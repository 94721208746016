<template>
  <div class="container">
    <PublicHeader :totalName="$t('safeCenter').editLoginPwd" />
    <div class="content">
      <div class="form">
        <div class="item">
          <div class="lable">{{ $t('safeCenter').oldPassword }}</div>
          <van-field v-model="oldPassword" :type="oldpsdType" :placeholder="$t('safeCenter').oldPasswordMsg"
            :right-icon="oldpsdType === 'password' ? 'eye' : 'closed-eye'"
            @click-right-icon="oldpsdType = oldpsdType === 'password' ? 'text' : 'password'">
          </van-field>
        </div>
        <div class="item">
          <div class="lable">{{ $t('safeCenter').newPassword }}</div>
          <van-field v-model="Password" :type="psdType" :placeholder="$t('safeCenter').newPasswordMsg"
            :right-icon="psdType === 'password' ? 'eye' : 'closed-eye'"
            @click-right-icon="psdType = psdType === 'password' ? 'text' : 'password'">
          </van-field>
        </div>
        <div class="item">
          <div class="lable">{{ $t('safeCenter').twoPassword }}</div>
          <van-field v-model="twoPassword" :type="twopsdType" :placeholder="$t('safeCenter').twoPasswordMsg"
            :right-icon="twopsdType === 'password' ? 'eye' : 'closed-eye'"
            @click-right-icon="twopsdType = twopsdType === 'password' ? 'text' : 'password'">
          </van-field>
        </div>
        <!-- 按钮 -->
        <van-button color="#C92B2E" block style="border-radius: 5px;height: 40px;" @click="handleSub">{{
          $t('safeCenter').btn }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      oldPassword: '',//原密码
      Password: '',//新密码
      twoPassword: '',//确认密码
      oldpsdType: "password", //输入框类型
      psdType: "password", //输入框类型
      twopsdType: "password", //输入框类型
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
  },
  methods: {
    // 提交
    async handleSub() {
      if (!this.checkForm()) return
      const form = {
        oldPassword: this.oldPassword,
        newPassword: this.Password,
      }
      await userApi.UpdatePassword(form)
      this.$toast({ message: this.$t('safeCenter').submsg })
      this.$router.go(-1)
    },
    checkForm() {
      if (!this.oldPassword) {
        this.$toast({ message: this.$t('safeCenter').oldPasswordMsg })
        return false
      }
      if (!this.Password) {
        this.$toast({ message: this.$t('safeCenter').newPasswordMsg })
        return false
      }
      if (!this.twoPassword) {
        this.$toast({ message: this.$t('safeCenter').twoPasswordMsg })
        return false
      }
      if (this.Password !== this.twoPassword) {
        this.$toast({ message: this.$t('safeCenter').twoinputMsg })
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 70px 15px 0 15px;
  background: #F6F6F6;

  .content {
    padding: 5px 15px 15px 15px;
    background: #FFFFFF;
    border-radius: 5px;

    .form {
      .lable {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin: 20px 0 10px 0;
      }

      .van-button {
        height: 37px;
        margin-top: 70px;
      }
    }
  }


}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  background: #F2F2F2;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 15px;
}
</style>
